import type { LinkExternal, Price } from '../../api-models'
import type { Country } from '../../standards'
import { createHttpEndpoint } from '../../utils'
import type {
  BuybackModelProduct,
  BuybackModelTrackingNumber,
  BuybackShippingMode,
} from '../models'

// TODO Remove this type once Address type is migrated to http-api from nuxt-module-address
export interface Address {
  city: string
  company?: string
  country: Country
  countryDialInCode?: string | null
  firstName: string
  firstNamePronunciation?: string | null
  lastName: string
  lastNamePronunciation?: string | null
  phone: string
  postalCode: string
  stateOrProvince: string
  street: string
  street2?: string
}

export interface CustomerAssessmentItem {
  name: string
  label: string
  value: string
}

export interface OrderDetails {
  id: number
  price: Price
  commission: string
  description?: string
  counterOfferPrice?: Price
  customerAssessment?: CustomerAssessmentItem[]
  offerExpiryDays?: number
  merchantCompany?: string
  transferCertificateLink?: LinkExternal
}

export interface BuybackShipping {
  labelLink?: LinkExternal
  mode?: BuybackShippingMode
  modeLabel?: string
  shipperName?: string
  trackingNumber?: BuybackModelTrackingNumber
  deliveryAddress?: Address
  returnAddress?: Address
  qrCodeUrl: string | null
}
export interface CustomerSaleDetailsPayload {
  product: BuybackModelProduct
  order: OrderDetails
  shipping?: BuybackShipping
}

/**
 * Buyback order details
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/buybackOrderDetails}
 */

export const getCustomerSaleDetails =
  createHttpEndpoint<CustomerSaleDetailsPayload>({
    method: 'GET',
    operationId: 'getCustomerSaleDetails',
    path: '/bm/buyback/v1/customer/orders/:id',
  })
