<template>
  <div class="flex gap-16">
    <div v-if="hasImage" class="h-64 w-64">
      <RevIllustration alt="product" :height="64" :src="image" :width="64" />
    </div>
    <div class="ml-4 flex grow flex-col justify-center">
      <RevTag
        v-if="hasTag"
        class="mb-3 self-start"
        :label="tag"
        variant="alternate"
      />
      <p class="body-2-bold">{{ title }}</p>
      <p class="mt-1">
        <span
          v-if="hasCounterOfferPrice"
          class="text-static-default-low body-2-bold"
          data-test="counter-offer-price"
        >
          {{ counterOfferPrice }}
        </span>
        <span :class="priceClasses">{{ price }}</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'

interface ProductInfoProps {
  image?: string
  title: string
  price: string
  tag?: string
  counterOfferPrice?: string
}

const props = withDefaults(defineProps<ProductInfoProps>(), {
  image: '',
  title: undefined,
  price: undefined,
  tag: '',
  counterOfferPrice: '',
})

const hasImage = computed(() => {
  return typeof props.image === 'string' && props.image.length > 0
})

const hasCounterOfferPrice = computed(() => {
  return (
    typeof props.counterOfferPrice === 'string' &&
    props.counterOfferPrice.length > 0
  )
})

const hasTag = computed(() => {
  return typeof props.tag === 'string' && props.tag.length > 0
})

const priceClasses = computed(() => {
  return hasCounterOfferPrice.value
    ? tw`text-static-default-low caption-striked line-through ml-6`
    : tw`body-2-bold text-primary-light`
})
</script>
